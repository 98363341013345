.specs {
  padding: 3%;
  width: 50%;
  max-width: 450px;
  /* height: 100vh; */
  /* background-color: rgba(0, 0, 0, 0.229); */
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  /* text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.665); */
  /* font-size: 1.5vw; */
  color: #002940;
}

.specs h2 {
  width: 100%;
  background-color: #fbea31;
  clip-path: polygon(5% 0, 100% 0, 95% 100%, 0 100%, 0 100%);
  text-align: center;

  margin: 0;
  padding: 0.7rem 0;
  font-size: x-large;
}

.specs h3 {
  font-weight: 600;
}

.specContent {
  margin-bottom: 120px;
  padding-bottom: 20px;
  width: 100%;
}

#firstSection {
  border-top: 1px solid #002940d0;
  padding-top: 20px;
  padding-bottom: 20px;
}

.specsSection {
  margin: 20px 0 5px 0;
  border-bottom: 1px solid #002940d0;
  padding-bottom: 20px;
}

.specsSection p {
  margin: 5px;
}

.specsSubtitle {
  font-weight: 600;
}

.paragraph {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: 250;
  line-height: 1.45;
  font-display: swap;
}

.ctaLink {
  width: 100%;
}

.callToAction {
  font-family: "Ubuntu", sans-serif;
  border: none;
  /* border-radius: 1rem; */
  padding: 1rem;
  color: #002940;
  background-color: #ccd4d9;
  /* box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.665); */
  font-weight: 700;
  cursor: pointer;
  transition: all 0.3s ease;
  width: 100%;
  min-width: 120px;
  font-size: medium;
  font-display: swap;
}

.callToAction:hover {
  background-color: #002940;
  color: #ccd4d9;
}

@media screen and (min-width: 360px) {
}
@media screen and (min-width: 568px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1024px) {
  .specs {
    padding-top: 0;
  }

  .specContent {
    margin-bottom: 75px;
  }
}

@media screen and (min-width: 1024px) and (min-height: 1365px) {
  .specs {
    justify-content: start;
  }
}

@media screen and (min-width: 1280px) {
}
@media screen and (min-width: 1366px) {
}

@media screen and (min-width: 1366px) and (min-height: 577px) {
  .specs h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 1440px) {
  .specs {
    padding-top: 0;
    width: 400px;
    padding-left: 20px;
  }

  .specContent {
    margin-bottom: 70px;
  }
}
@media screen and (min-width: 1500px) and (min-height: 660px) {
  .specs h1 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .specs h3 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 1920px) {
  .specs {
    margin-top: 0;
    margin-right: 100px;
    padding-top: 0;
    padding-left: 0;
  }
}
@media screen and (min-width: 2560px) {
}

/* IPAD PRO */
