.leftSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  height: 40vh;
  min-width: 150px;
  max-height: 475px;
  padding-right: 20px;
}

@media screen and (min-width: 360px) {
  .leftSide {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    height: auto;
  }
}

@media screen and (min-width: 375px) and (min-height: 667px) {
}

@media screen and (min-width: 375px) and (min-height: 812px) {
}

@media screen and (min-width: 425px) {
}

@media screen and (min-width: 568px) {
}
@media screen and (min-width: 768px) {
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1024px) {
  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    height: 57.5vh;
    min-width: 150px;
    font-size: 15px;
  }
}
@media screen and (min-width: 1280px) {
}
@media screen and (min-width: 1366px) {
}
@media screen and (min-width: 1366px) and (min-height: 577px) {
}

@media screen and (min-width: 1440px) {
  .leftSide {
    height: 57vh;
  }
}
@media screen and (min-width: 1536px) {
}

@media screen and (min-width: 1536px) and (min-height: 660px) {
  .leftSide {
    height: 68vh;
  }
}

@media screen and (min-width: 1920px) {
  .leftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
    height: 52vh;
    min-width: 150px;
  }
}
@media screen and (min-width: 2560px) {
  .leftSide {
    width: 20%;
  }
}
