@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;700&display=swap");

@import url("https://fonts.cdnfonts.com/css/helvetica-neue-5");

body {
  font-family: "Ubuntu", sans-serif;
  /* background-image: url("https://369.ad/img/home-369-back.jpg"); */
  background-color: white;
  backdrop-filter: blur(3px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  padding: 5%;
  /* width: 100vw; */
  /* height: 100vh; */
  /* overflow: hidden; */
  color: #002940;
  font-display: swap;
}

body button {
  font-family: "Ubuntu", sans-serif;
  font-display: swap;
}

.App {
  text-align: center;
}

.swiper-pagination-bullet,
.swiper-pagination-bullet-active,
.swiper-pagination-bullet-active-main {
  /* color: white !important; */
  color: #00293f !important;
  /* margin-top: ; */
}

.swiper-navigation:hover {
  color: rgb(141, 141, 141);
}
/* .swiper-slide-next,
.swiper-slide-prev {
  max-height: 480px;
  max-width: 320px;
}

.swiper-slide {
  margin-right: 0 !important;
  left: 20% !important;
}

.swiper-slide-next,
.swiper-slide-prev {
  transform: translate3d(0px, 0px, -200px) rotateX(0deg) rotateY(0deg) scale(1) !important;
}
*/

.swiper {
  padding-top: 3% !important;
}

.swiper-button-next,
.swiper-button-prev {
  transition: all ease 0.3s;
  z-index: 1000000000;
  color: #00293f;
}

.swiper-button-next:hover,
.swiper-button-prev:hover {
  transform: scale(1.3);
}
/*
.swiper-wrapper {
}

.swiper-slide:not(.swiper-slide-active):not(.swiper-slide-next):not(
    .swiper-slide-prev
  ) {
  transform: translate3d(0px, 0px, -300px) rotateX(0deg) rotateY(0deg) scale(1) !important;
}
*/
@media screen and (min-width: 360px) {
  .swiper {
    padding-top: 5% !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    margin-top: 230px;
    transform: scale(0.75);
  }

  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    transform: scale(1);
  }
}
/*
@media screen and (min-width: 375px) {
  .swiper-slide {
    top: 10px;
    left: 6% !important;
    width: 40% !important;
  }
}

@media screen and (min-width: 413px) {
  .swiper-slide {
    top: 10px;
    left: 10% !important;
    width: 40% !important;
  }
}

@media screen and (min-width: 568px) {
  .swiper-slide {
    left: 19% !important;
  }
}

@media screen and (min-width: 600px) {
  .swiper-slide {
    left: 42% !important;
    width: 29.5% !important;
  }
}
*/
@media screen and (min-width: 768px) {
  .swiper {
    padding-top: 3% !important;
    margin-top: 10px;
  }

  .swiper-button-prev {
    margin-top: 0;
    margin-left: 100px;
  }

  .swiper-button-next {
    margin-right: 100px;
    margin-top: 0;
  }

  .swiper-button-prev,
  .swiper-button-next {
    transform: scale(1);
  }
}
/*
@media screen and (min-width: 992px) {
  .swiper-slide {
    left: 42% !important;
    width: 35% !important;
  }
}*/

@media screen and (min-width: 1024px) {
  .swiper {
    padding-top: 3% !important;
    margin-top: 0px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    transform: scale(1.3);
  }
  .swiper-button-prev {
    margin-top: 0;
    margin-left: 0px;
  }

  .swiper-button-next {
    margin-right: 0px;
    margin-top: 0;
  }
}
/*
@media screen and (min-width: 1280px) {
  .swiper-slide {
    left: 27% !important;
    width: 40% !important;
  }
}

@media screen and (min-width: 1366px) {
  .swiper-slide {
    left: 27% !important;
    width: 40% !important;
  }
} */

/* ESTO YA ESTABA COMENTADO */

@media screen and (min-width: 1440px) {
  .swiper-button-prev {
    margin-left: 175px;
  }

  .swiper-button-next {
    margin-right: 175px;
  }
}
/* 
@media screen and (min-width: 1536px) {
  .swiper-slide {
    left: 27% !important;
  }
} */

@media screen and (min-width: 1536px) and (min-height: 660px) {
  body {
    padding-top: 30px;
  }
}

@media screen and (min-width: 1920px) {
  .swiper-button-prev {
    margin-left: 250px;
  }

  .swiper-button-next {
    margin-right: 250px;
  }
}
/* 
@media screen and (min-width: 2300px) {
  .swiper-slide {
    width: 30% !important;
    left: 56% !important;
  }
}

@media screen and (min-width: 2560px) {
  .swiper-slide {
    width: 35% !important;
    left: 51% !important;
  }
} */
