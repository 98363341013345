.specs {
  position: absolute;
  padding: 30px;
  width: 80%;
  height: 50vh;
  background-color: rgba(0, 0, 0, 0.804);
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  transform: translateY(-120px);
  font-size: 1.5vw;
  z-index: 10;
  margin-top: 20%;
  transition: all ease-in-out 0.3s;
}

.specs h3 {
  font-weight: 500;
}

.specContent {
  margin-bottom: 20px;
  width: 100%;
}

.specsSection {
  margin: 20px 0 5px 0;
}

.specsSection p {
  margin: 5px;
}

.specsSubtitle {
  font-weight: bolder;
}

.paragraph {
  font-family: "Helvetica Neue", sans-serif;
  font-weight: lighter;
  line-height: 1.45;
  font-display: swap;
}

.btnsContainer {
  display: flex;
  justify-content: center;
}

.ctaLink {
  width: 100%;
}

.callToAction {
  font-family: "Ubuntu", sans-serif;
  background-color: #ccd4d9;
  font-weight: 600;
  color: #002940;
  width: auto;
  min-width: 120px;
  padding: 1rem;
  border: none;
  font-size: medium;
  cursor: pointer;
  transition: all ease 0.3s;
  font-display: swap;
}

.callToAction:hover {
  color: white;
  background-color: #00293f;
  cursor: pointer;
}

.callToAction a {
  text-decoration: none;
  color: #002940;
}

.callToAction a:hover {
  color: #002940;
  cursor: pointer;
}

.specsBtn {
  font-family: "Ubuntu", sans-serif;
  background-color: #ccd4d9;
  font-weight: 600;
  color: #002940;
  width: auto;
  min-width: 120px;
  padding: 1rem;
  border: none;
  font-size: medium;
  cursor: pointer;
  transition: all ease 0.3s;
  font-display: swap;
}

.specsBtn:hover {
  color: white;
  background-color: #00293f;
  cursor: pointer;
}

.close {
  position: absolute;
  right: 10px;
  top: 0px;
  cursor: pointer;
  transition: all ease-in-out 0.3s;
}
.close:hover {
  color: rgb(110, 110, 110);
}

@media screen and (min-width: 360px) {
  .specs {
    font-size: 1.5vh;
    top: 150px;
    height: 500px;
  }

  .callToAction {
    margin-left: 2%;
    padding: 5px;
  }

  .specsBtn {
    padding: 5px;
  }
  .btnsContainer {
    margin-top: 50px;
  }
}

@media screen and (min-width: 375px) and (min-height: 667px) {
  .btnsContainer {
    width: 80%;
    bottom: 50px;
    position: relative;
  }
}

@media screen and (min-width: 375px) and (min-height: 812px) {
  .btnsContainer {
    width: 80%;
    bottom: 0px;
    position: relative;
  }
}

@media screen and (min-width: 568px) {
  .specs {
    font-size: 2vh;
    top: 100px;
  }
  .callToAction {
    font-size: 1.5vw;
  }
  .specsBtn {
    font-size: 1.5vw;
  }
}
@media screen and (min-width: 768px) {
  .callToAction {
    font-size: 2vw;
  }
  .specsBtn {
    font-size: 2vw;
  }
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1024px) {
}
@media screen and (min-width: 1280px) {
}
@media screen and (min-width: 1366px) {
}
@media screen and (min-width: 1441px) {
}
@media screen and (min-width: 1536px) {
}
@media screen and (min-width: 1920px) {
}
@media screen and (min-width: 2560px) {
}
