.container {
  width: 100%;
  display: flex;
  color: white;
  margin-top: 5%;
}

.swiperContainer {
  /* width: 50%; */
  height: 550px;
  margin: 40px;
  margin-top: 10%;
  min-height: 500px;
  padding-top: 50px;
}

.swiper-pagination-bullet-active {
  background-color: #000 !important;
}

.swiperSlide {
  height: 480px;
}

iframe {
  border: none;
  /*box-shadow: 0px -5px 10px rgba(0, 0, 0, 0.665);*/
}

.slideContent {
  position: relative;
}

.inactiveSlide {
  position: absolute;
  top: 0;
  left: 40px;
  height: 480px;
  width: 320px;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.045);
  backdrop-filter: blur(5px);
  filter: blur(0.6);
  transition: all 0.3s ease-in-out;
}

.wspLogo {
  position: absolute;
  width: 3rem;
  left: 10px;
  bottom: 10px;
  cursor: pointer;
  transition: all 0.5s ease;
}

.wspLogo:hover {
  position: absolute;
  width: 3rem;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
  transform: scale(1.5) translate(10px, -10px);
}

@media screen and (min-width: 360px) {
  .container {
    flex-direction: column;
    align-items: center;
  }

  .swiperContainer {
    width: 100%;
    margin: 0;
    padding-top: 0;
    margin-top: -20px;
    top: 40px;
    height: 540px;
    padding-top: 20px;
  }

  .inactiveSlide {
    left: 100px;
  }
}

@media screen and (min-width: 375px) and (min-height: 667px) {
  .container {
    /* padding-top: 20px; */
  }

  .inactiveSlide {
    left: 30px;
  }
}

@media screen and (min-width: 375px) and (min-height: 812px) {
  .container {
    /* margin-top: 20px; */
  }
  .inactiveSlide {
    left: 30px;
  }
}

@media screen and (min-width: 425px) {
  .inactiveSlide {
    left: 50px;
  }
}

@media screen and (min-width: 568px) {
  .swiperContainer {
    width: 100%;
    margin: 0;
    margin-top: 15%;
    min-height: 500px;
  }
}

@media screen and (min-width: 768px) {
  .swiperContainer {
    width: 100%;
    margin: 0;
    margin-top: 10%;
    padding-top: 30px;
    min-height: 500px;
  }

  .inactiveSlide {
    left: 105px;
  }
}

@media screen and (min-width: 992px) {
  .inactiveSlide {
    left: 83px;
  }
}

@media screen and (min-width: 1024px) {
  .container {
    flex-direction: row;
    align-items: start;
    /* margin-top: 0; */
  }
  .swiperContainer {
    /* width: 60%; */
    margin: 0;
    margin-top: 0;
  }

  .swiperSlide {
    transform: translateX(-44px);
  }
  .inactiveSlide {
    left: 45px;
  }
}

@media screen and (min-width: 1280px) {
  .inactiveSlide {
    left: 20px;
  }
}

@media screen and (min-width: 1366px) {
  .inactiveSlide {
    left: 33px;
  }
}

@media screen and (min-width: 1366px) and (min-height: 577px) {
  .swiperContainer {
    margin-top: 0;
  }
  .inactiveSlide {
    left: 49px;
  }
}

@media screen and (min-width: 1366px) and (min-height: 768px) {
  .swiperContainer {
    margin-top: 0;
  }
}

/* IPAD PRO */
@media screen and (min-width: 1366px) and (max-width: 1420px) and (min-height: 1024px) {
  .container {
    flex-direction: row;
    align-items: start;
  }
  .swiperContainer {
    width: 60%;
    margin: 0;
    margin-top: 15%;
    margin-left: -21px;
    margin-right: -21px;
  }
}

@media screen and (min-width: 1440px) {
  .swiperContainer {
    margin-top: 0;
  }

  .slideContent {
    position: relative;
  }
  .inactiveSlide {
    left: 162px;
  }
}

@media screen and (min-width: 1536px) {
  .swiperContainer {
    margin-top: 3%;
  }
  .inactiveSlide {
    left: 186px;
  }
}

@media screen and (min-width: 1536px) and (min-height: 660px) and (max-height: 1023px) {
  .swiperContainer {
    margin-top: 0;
    padding-top: 15px;
  }
  .inactiveSlide {
    left: 190px;
  }
}

@media screen and (min-width: 1536px) and (min-height: 1024) {
  .swiperContainer {
    margin-top: 0;
    padding-top: 15px;
  }
  .inactiveSlide {
    left: 150px;
  }
}

@media screen and (min-width: 1920px) {
  .swiperContainer {
    /* width: 60%; */
  }
  .swiperContainer {
    /* margin-top: 5%; */
  }
  .slideContent {
    position: relative;
  }

  .inactiveSlide {
    left: 162px;
  }
}

@media screen and (min-width: 1920px) and (max-height: 1023px) {
  .swiperContainer {
    /* width: 60%; */
  }
  .swiperContainer {
    /* margin-top: 5%; */
  }
  .slideContent {
    position: relative;
  }

  .inactiveSlide {
    left: 195px;
  }
}

@media screen and (min-width: 2000px) {
  .inactiveSlide {
    left: 14px;
  }
}

@media screen and (min-width: 2100px) {
  .inactiveSlide {
    left: 120px;
  }
}

@media screen and (min-width: 2560px) {
  .swiperContainer {
    margin-top: 5%;
  }

  .inactiveSlide {
    left: 180px;
  }
}
