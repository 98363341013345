.redirectButton {
  font-family: "Ubuntu", sans-serif;
  background-color: #ccd4d9;
  font-weight: 600;
  color: #002940;
  width: auto;
  min-width: 120px;
  padding: 1rem;
  border: none;
  font-size: medium;
  cursor: pointer;
  transition: all ease 0.3s;
  font-display: swap;
}

.redirectButton:hover {
  background-color: #002940;
  color: #ccd4d9;
}

.icon {
  margin-left: 5px;
}

@media screen and (min-width: 360px) {
  .redirectButton {
    margin-left: 2%;
    padding: 5px;
  }
}
@media screen and (min-width: 568px) {
}
@media screen and (min-width: 768px) {
  .redirectButton {
    margin-left: 4%;
  }
}
@media screen and (min-width: 992px) {
}

@media screen and (min-width: 1024px) and (min-height: 1365px) {
}

@media screen and (min-width: 1024px) {
  .redirectButton {
    width: 135px;
    padding: 1rem;
  }
}
@media screen and (min-width: 1280px) {
}
@media screen and (min-width: 1366px) {
}

@media screen and (min-width: 1366px) and (min-height: 577px) {
}

@media screen and (min-width: 1440px) {
  .redirectButton {
    width: 140px;
  }
}
@media screen and (min-width: 1536px) and (min-height: 660px) {
}
@media screen and (min-width: 1920px) {
  .redirectButton {
    width: 180px;
  }
}
@media screen and (min-width: 2560px) {
}

/* IPAD PRO */
