.container {
  width: 100%;
  /* height: 9rem; */
  /* background-color: rgba(0, 0, 0, 0.378); */
  display: flex;
  flex-direction: column;
  align-items: start;
}

/* .leftSide {
  max-width: 50%;
  display: flex;
  align-items: center;
} */

.logo {
  width: 40%;
  min-width: 120px;
  max-width: 120px;
  /* filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.8)); */
}

.container p {
  background-color: #002940;
  color: white;
  padding: 10px 30px 10px 30%;
  clip-path: polygon(0 0, 100% 0, 98% 100%, 0 100%, 0 100%);
}

.selector {
  width: 50%;
}

.nabvar {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 10px;
  margin: 1rem;
  color: white;
}

.navbarElement {
  cursor: pointer;
}

.franja {
  width: 200px;
  top: 0px;
  margin-top: -90px;
  z-index: 10;
  margin-right: 5%;
  /* filter: drop-shadow(2px 2px 3px rgba(0, 0, 0, 0.8)); */
}

@media screen and (min-width: 360px) {
  .container {
    display: inline-block;
    /* height: 1rem; */
  }

  .logo {
    /* margin-top: 1%; */
    width: 30%;
    margin-left: none;
  }
  .container p {
    padding: 10px;
    margin-bottom: 0;
    clip-path: none;
  }
}

@media screen and (min-width: 568px) {
  .container {
    display: flex;
    /* height: 7rem; */
  }
  .franja {
    display: inline-block;
    margin-top: -100px;
  }
  .logo {
    /* margin-top: 1%; */
    width: 20%;
    /* margin-left: 1rem; */
  }

  .container p {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 768px) {
  .container p {
    padding: 10px 30px 10px 20%;
    background-color: #002940;
    clip-path: polygon(0 0, 100% 0, 98% 100%, 0 100%, 0 100%);
  }
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1024px) {
}
@media screen and (min-width: 1280px) {
}
@media screen and (min-width: 1366px) {
}

@media screen and (min-width: 1366px) and (min-height: 577px) {
  .container {
    /* height: 18rem; */
  }
  .franja {
    margin-top: -90px;
  }
}

@media screen and (min-width: 1441px) {
}
@media screen and (min-width: 1536px) {
}

@media screen and (min-width: 1536px) and (min-height: 660px) {
  .container {
    flex-direction: row;
    align-items: end;
  }

  .container p {
    margin-left: 50px;
    padding: 10px 30px 10px 25%;
  }
}

@media screen and (min-width: 1920px) {
  .container {
    flex-direction: column;
    align-items: start;
  }
  .container p {
    margin-left: 0px;
  }
}
@media screen and (min-width: 2560px) {
}
