.catSelect {
  font-family: "Ubuntu", sans-serif;
  background-color: #ccd4d9;
  font-weight: 600;
  color: #002940;
  width: 20%;
  min-width: 120px;
  height: auto;
  padding: 1rem;
  border: none;
  /* border-radius: 5px; */
  font-size: medium;
  cursor: pointer;
  /* box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.65); */
  text-align: center;
  transition: all 0.3s ease;
  font-display: swap;
}

.catSelect:hover {
  background-color: #002940;
  color: #ccd4d9;
}

.catSelect option {
  transition: all 0.3s ease;
  text-align: center;
}

@media screen and (min-width: 360px) {
  .catSelect {
    padding: 5px;
  }
}
@media screen and (min-width: 568px) {
}

@media screen and (min-width: 768px) {
  .catSelect {
    padding: 10;
  }
}
@media screen and (min-width: 992px) {
}
@media screen and (min-width: 1024px) {
  .catSelect {
    width: 135px;
    padding: 1rem;
  }
}
@media screen and (min-width: 1280px) {
}
@media screen and (min-width: 1366px) {
}
@media screen and (min-width: 1440px) {
  .catSelect {
    width: 140px;
  }
}
@media screen and (min-width: 1536px) {
}
@media screen and (min-width: 1920px) {
  .catSelect {
    width: 180px;
  }
}
@media screen and (min-width: 2560px) {
}
